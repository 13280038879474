/* App root */
body {
  background-color: $worksurface-background-primary-color;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

.aa-exclusion-parameter {
  label {
    // label on top of input
    display: flex;
    flex-direction: column;
  }
}
