/* WelcomePage */
.welcome-page {
  background-color: $worksurface-background-primary-color;
  width: 100%;
  height: 100vh;

  .welcome-content {
    padding: 36pt 20% 0 20%;

    .goto-analysis-frame {
      padding: 0 0 24pt 0;
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        background-color: $worksurface-background-primary-color;
        border-color: $headline-background-color-light;
        color: $headline-background-color;
      }
    }
  }
}

/* Informational page */
.informational-page {
  background-color: $worksurface-background-primary-color;
  width: 100%;
  height: 100vh;

  .informational-content {
    padding: 36pt 20% 0 20%;
  }
}

/* ResultPage */
.result-page {
  background-color: $worksurface-background-primary-color;
  width: 100%;
  height: 100vh;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    .pdb-viewer-frame {
      .viewport {
        width: 200px;
        height: 250px;
      }
    }

    h2 {
      display: flex;
      justify-content: center;
    }
  }

  .result-text {
    padding: 36pt 30% 0 30%;

    .maillist-input-frame {
      display: flex;

      .mail-input {
        width: 32ch;
      }
    }
  }

  .result-evaluation {
    padding: 20px 0 0 0;
    .evaluation-frame {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .pdb-viewer-frame {
        width: 800px;
        .pdb-viewer-frame {
          width: 800px;
          .viewport {
            width: 100%;
            height: 560px;
          }
        }
      }
      .selection-frame {
        padding: 0 15px 0 15px;

        height: 560px;
        overflow-y: auto;
        .select-pdb-frame {
          display: flex;
        }
        .pdb-table-frame {
        }
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 0 0 0 0;
      background-color: $worksurface-background-primary-color;
    }

    .select-pdb {
      width: 32ch;
    }
    .file-download-frame {
      padding: 15px 15px 0 15px;

      .file-link {
        color: $primary-font-input-color;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }
}

/* Headline */
.headline-frame {
  background-color: $headline-background-color;
  height: $headline-height;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .headline-flexrow-logo-text {
    display: flex;
    flex-direction: row;
  }

  .links {
    display: flex;
    flex-direction: row;
    height: 100%;

    .link {
      font-size: 10pt;
      color: $secondary-font-headline-color;
      background-color: $headline-background-color-light;
      cursor: pointer;
      border-color: $headline-background-color;
    }

    .link:hover {
      font-size: 10pt;
      color: $secondary-font-headline-color;
      background-color: $headline-background-color;
      cursor: pointer;
    }
  }

  .logo {
    height: $headline-height;
  }

  img {
    width: 100%;
    max-width: 200px;
    height: 85%;
  }

  .text {
    .headline {
      font-size: 36pt;
      padding-left: 3vw;
      color: $primary-font-headline-color;
    }

    .subtitle {
      padding-left: 3vw;
      font-size: 20pt;
      color: $secondary-font-headline-color;
    }
  }
}

/* LabeLizerPage */
.labelizer-page {
  background-color: $worksurface-background-primary-color;
  width: 100%;

  .worksurface-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 0 auto;

    .section-frame-padding {
      padding: 18px 70px 10px 70px;

      .section-body {
        background-color: $worksurface-background-primary-color;
        display: flex;
      }

      .border {
        height: 10pt;
        border-bottom: 1px solid $headline-background-color-light;
      }
    }
  }

  /* Section 1 */
  .choose-name {
    h2 {
      font-size: $headline-fontsize;
    }

    .choose-name-content {
      padding-left: $section-input-content-padding;
    }
  }

  /* Section 2 */
  .choose-protein {
    h2 {
      font-size: $headline-fontsize;
    }

    .choose-mode-content {
      padding-left: $section-input-content-padding;

      .headline {
        font-size: $input-headline-fontsize;
      }
      .choose-mode-input-frame {
        display: flex;

        .fret-text {
          font-size: $input-text-fontsize;
        }
        .toggle-frame {
          font-size: $input-text-fontsize;
          padding-left: 5px;
          padding-top: 3px;

          .toggle {
            font-size: 14pt;
          }
        }
        .singlelabel-text {
          font-size: $input-text-fontsize;
          padding-left: 10px;
        }
      }
    }

    .choose-pdbidorfile-content {
      display: flex;
      padding-left: $section-input-content-padding;
      .select-pdbid-frame {
        .inputs {
          display: flex;

          .input {
            padding-right: 15px;
          }
          .select {
            width: 12ch;
          }
        }
      }

      .upload-custom-pdb {
        .upload-button-frame {
          .select {
            width: 12ch;
          }
        }
      }
    }
  }
  .display-grid {
    padding: 0 0 10px 0;
    display: grid;
    grid-template-columns: 400px 400px;
    grid-template-rows: 100px 190px;
    gap: 1px 1px;
    grid-template-areas:
      "choose-pdbidorfile-content pdb-viewer-frame"
      "choose-proteinname-input pdb-viewer-frame";

    .choose-proteinname-input {
      width: 48ch;
      padding-left: $section-input-content-padding;
    }

    .pdb-viewer-frame {
      .viewport {
        width: 100%;
        height: 260px;
        .pdb-toolbar {
          display: flex;
          flex-direction: row;
          .color-scale {
            display: flex;
            width: 300px;
          }
        }
      }
    }

    .display-pdbid {
      padding-left: $section-input-content-padding;
      padding-top: 15px;
      display: flex;

      .select-chain {
        padding-left: 15px;

        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ) {
          background-color: $headline-background-color;
          color: $primary-font-headline-color;
          border-color: $headline-background-color;
        }

        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ):first-child {
          border-color: $headline-background-color;
        }

        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          ):last-child {
          border-color: $headline-background-color;
        }
        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          )::before {
          background-color: $headline-background-color;
        }

        .ant-radio-button-wrapper:hover {
          background-color: $headline-background-color-light;
          color: $primary-font-headline-color;
        }

        .select-buttons {
          display: flex;
          .checked {
            background-color: $headline-background-color;
            color: $primary-font-headline-color;
          }
        }
      }

      .remove-selection-frame {
        .ant-btn {
          line-height: 1.5715;
        }
        .button {
          display: flex;

          .trashcan {
            color: $headline-background-color;
            line-height: 1.75;
          }
        }
      }
    }
  }

  .choose-fluorescence {
    h2 {
      font-size: $headline-fontsize;
    }

    .donor {
      padding: 0 0 0 25px;
      width: 400px;
    }

    .acceptor {
      padding: 15px 0 0 25px;
      width: 400px;
    }

    .foerster-radius {
      padding: 15px 0 0 25px;
      width: 400px;
      display: flex;
    }

    .foerster-loading-spinner {
      padding: 20pt 0 0 0;
    }

    .singlelabel {
      padding: 0 0 0 2vw;
      width: 400px;
    }
  }

  .parameter-segment {
    h2 {
      font-size: $headline-fontsize;
    }

    .ant-checkbox {
      padding: 8pt;
    }

    .rc-slider-track,
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $headline-background-color;
      border-color: $headline-background-color;
    }

    .rc-slider-dot-active,
    .rc-slider-handle {
      border-color: $headline-background-color;
    }

    .collapse {
      cursor: pointer;
    }

    .selector-frame {
      padding: 0 0 15px 2vw;
      width: 900px;

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .parameter {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .coming-soon {
          font-size: 12pt;
          color: #0620446b;
        }

        input[type="checkbox"] {
          width: 18px;
          height: 18px;
        }

        .title {
          font-size: $input-text-fontsize;
        }

        .weight {
          width: 200px;
          padding: 5px 50px 20px 0;
        }

        .sensitivity {
          width: 200px;
          padding: 5px 10px 0 10px;
        }
      }
    }
  }

  .start-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .start-frame {
      padding: 0 10% 0 0;

      .ant-btn {
        height: 48pt;
      }

      .start-button {
        width: fit-content;
        font-size: 24pt;
      }
    }
  }

  .info {
    .icon {
      font-size: $input-headline-fontsize;
      padding-left: 5px;
    }
  }
}

/* ant-default-styles */
.select:hover,
.ant-select:hover,
.ant-select-selector:hover,
.ant-select-selector,
.ant-input:hover {
  border-color: $headline-background-color;
}

.ant-btn:hover {
  border-color: $headline-background-color;
  color: $headline-background-color;
}

/* Loading bubbles */

.loading-bubbles {
  display: flex;
  flex-direction: column;
  align-items: center;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $headline-background-color;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}
