/* Constants */

/* Background colors */
$worksurface-background-primary-color: rgba(255, 255, 255, 1);
$worksurface-background-secondary-color: rgba(247, 247, 247, 1);
$headline-background-color: #062044bb;
$headline-background-color-light: #0620447f;

/* font-colors */
$primary-font-input-color: #010b1d;
$secondary-font-input-color: #021530;

$primary-font-headline-color: #f5f5f5fb;
$secondary-font-headline-color: #f5f5f5df;

/* font-sizes */
$text-fontsize: 18pt;
$input-headline-fontsize: 12pt;
$input-text-fontsize: 18pt;
$headline-fontsize: 24pt;

/* Component-sizes */
$headline-height: 120px;

/* Paddings */

/** Section content vs Section headline padding */
$section-input-content-padding: 4ch;

@import "scss/shared.scss";
/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  @import "scss/desktop.scss";
}
@media only screen and (max-width: 768px) {
  @import "scss/mobile.scss";
}
